import { Box } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PartnerApi from '../../api-partner/partner/partner.api';
import AuthenApi from '../../api/authen/authen.api';
import OptionsApi from '../../api/option/option.api';
import Loading from '../../components/loading/loading';
import { swalError } from '../../components/notification/swal';
import { colors } from '../../constants/colors';
import { defaultDataRegister } from './default';
import LayoutCareer from './layout-career/layout-career';
import LayoutComplete from './layout-complete/layout-complete';
import LayoutContact from './layout-contact/layout-contact';
import LayoutPersonal from './layout-personal/layout-personal';
import LayoutProduct from './layout-product/layout-product';
import LayoutRegister from './layout-register/layout-register';

const steps = [
  'ลงทะเบียน',
  'สินค้า',
  'ส่วนตัว',
  'อาชีพ',
  'ผู้ติดต่อ',
];

export default function Register() {
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState<number>(0)
  const [data, setData] = useState<any>(defaultDataRegister)
  const [downPayment, setDownPayment] = useState<any>()
  const [checkedItems, setCheckedItems] = useState<any>()

  const [loading, setLoading] = useState(false)
  const [optionMaster, setOptionMaster] = useState<any[]>([])

  const onNextStep = (index: number) => {
    setActiveStep(index)
  }

  const onBackStep = (index: number) => {
    setActiveStep(index)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    setData(defaultDataRegister)
  }, [defaultDataRegister]);

  const loadAddress = useCallback(() => {
    OptionsApi.address().then((res) => {
      if (res.status === 200) {
        setOptionMaster(res.data.address)
      }
    })
  }, [])

  useEffect(() => {
    console.log(data)
  }, [data]);
  useEffect(() => {
    loadAddress()
  }, [loadAddress]);

  const onSubmit = async () => {
    if (data) {
      setLoading(true)
      if (data.job?.studentImages?.length > 0) {
        data.job.studentImages.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.job?.transcriptOrTermFees?.length > 0) {
        data.job.transcriptOrTermFees.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.imeiImages?.images?.length > 0) {
        data.profile?.imeiImages?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.profile?.iphoneStorage?.images?.length > 0) {
        data.profile?.iphoneStorage?.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.statement?.images?.length > 0) {
        data.statement.images.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveWork?.length > 0) {
        data.proveWork.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.riderAppication?.length > 0) {
        data.riderAppication.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });

      }
      if (data.governmentOfficial?.length > 0) {
        data.governmentOfficial.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.proveOwnBusinessImage?.length > 0) {
        data.proveOwnBusinessImage.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }
      if (data.salesChannelsImage?.images?.length > 0) {
        data.salesChannelsImage?.images?.forEach((item: any) => {
          delete item.name;
          delete item.img;
        });
      }

      const res = await AuthenApi.registerBeforeVerify(data)
      if (res.status === 200) {
        setLoading(false)
        window.scrollTo(0, 0);
        setActiveStep(5)
        if (data?.chatcone?.social_id) {
          const { id } = res.data
          const payload = {
            chatcone: data?.chatcone,
            process_type: 'REGISTER',
            register_id: id,
            ref_id: id,
            route_from: `${process.env.REACT_APP_URL}${pathname}`
          }
          await PartnerApi.sendChatconeByProcess(payload)
        }
      } else {
        setLoading(false)
        return swalError('ระบบมีปัญหากรุณาลองใหม่อีกครั้ง')
      }
      setLoading(false)
    }
  }
  return (

    <Box sx={{ width: '100%', paddingTop: 20 }}>
      <Loading show={loading} type="fullLoading" />
      <div>
        <Stepper activeStep={activeStep} alternativeLabel
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: colors.themeMainColor
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
              color: colors.black
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === 0 && (
            <LayoutRegister onClickNext={() => { onNextStep(1) }} data={data} onChange={setData} optionMaster={optionMaster} />
          )}
          {activeStep === 1 && (
            <LayoutProduct onClickNext={() => { onNextStep(2) }} data={data} onChange={setData} downPaymentVal={setDownPayment} checkedItemsVal={setCheckedItems} checkedItems={checkedItems} />
          )}
          {activeStep === 2 && (
            <LayoutPersonal onClickNext={() => { onNextStep(3) }} onClickBack={() => { onBackStep(1) }} data={data} onChange={setData} optionMaster={optionMaster} />
          )}
          {activeStep === 3 && (
            <LayoutCareer onClickNext={() => { onNextStep(4) }} onClickBack={() => { onBackStep(2) }} data={data} onChange={setData} downPayment={downPayment} />
          )}
          {activeStep === 4 && (
            <LayoutContact onSubmit={() => { onSubmit() }} onClickBack={() => { onBackStep(3) }} data={data} onChange={setData} />
          )}
          {activeStep === 5 && (
            <LayoutComplete profile={data.profile} type={data.type} chatcone={data.chatcone} />
          )}
        </div>
      </div>

    </Box>

  );
}
